<template>
  <div v-if="componentData" data-app>
    <HomeRootComponent :parent-component-data="getComponentData" />
  </div>
</template>

<script>
import HomeRootComponent from '../components/HomeRootComponent'
import AuthenticatedPage from '../components/AuthenticatedPage'

export default {
  components: { HomeRootComponent },
  extends: AuthenticatedPage,
  transition (to, from) {
    if (!from) {
      return 'slide-left'
    }
    return +to.query.page < +from.query.page ? 'slide-right' : 'slide-left'
  },
  data () {
    return {
      componentData: null,
      mainFeedData: {}
    }
  },
  head () {
    return {
      title: 'GOTit Social Ecommerce',
      meta: [
        { hid: 'description', name: 'description', content: process.env.gotitDescription },
        { hid: 'application-name', name: 'application-name', content: 'GOTit' },
        {
          hid: 'image',
          name: 'image',
          content: process.env.gotitLogo
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: process.env.gotitLogo
        },
        { hid: 'url', name: 'url', content: 'https://gotitstores.com' },
        { hid: 'og:url', name: 'og:url', content: 'https://gotitstores.com' },
        { hid: 'facebook-domain-verification', name: 'facebook-domain-verification', content: '7pz0i80r9j2le3mssvhc6xpul2odtn' },
        { hid: 'p:domain_verify', name: 'p:domain_verify', content: 'f761d3270cc93e75f104d7b225d6efc1' }
      ]
    }
  },
  computed: {
    getComponentData () {
      return this.componentData
    }
  },
  methods: {
    onUserReady () {
      this.componentData = { components: [{ componentId: 'toolbar', type: 'ToolbarComponent' }, { componentId: 'left_component', type: 'LeftComponent' }, { componentId: 'right_component', type: 'RightComponent' }, { components: [{ type: 'MainFeedItemComponent' }], componentId: 'center_component', stream: { filter: 'ALL', endpoint: '/items/select', gender: true, limit: 20, includeComments: true, includeProperties: true, mainFeed: true }, spreading: 'REPEAT', type: 'ListComponent', parameters: { width: '100%', height: '100%' } }], componentId: 'ROOT', type: 'HomeRootComponent' }
      this.$store.dispatch('api/sendLogMessage', 'feed_presented').then().catch()
      this.$store.dispatch('api/trackEvent', {
        eventName: 'feed_presented',
        params: {
          path: this.$route.path
        }
      }).then().catch()
    }
  }
}
</script>

<style>

  .center {
    flex-grow: 1;
  }

  .main > div {
    /*flex: 1;*/
    /*border: 1px solid #eee;*/
  }

  .container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .title {
    font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', Arial, sans-serif;
    display: block;
    font-weight: 300;
    font-size: 100px;
    color: #35495e;
    letter-spacing: 1px;
  }

  .subtitle {
    font-weight: 300;
    font-size: 42px;
    color: #526488;
    word-spacing: 5px;
    padding-bottom: 15px;
  }

  .links {
    padding-top: 15px;
  }

  .rv_container .recyclerview {
    width: 560px;
  }

  .header-sidebar h4 {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
  }

  .header-sidebar img {
    width: 90px;
    height: 90px;
  }

  > button {
    margin-left: 10px;
  }

  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, .07) !important;
    border-radius: 0px !important;
  }

  .vs-sidebar {
    max-width: 675px;
  }

  .mf_item_properties > div {
    text-align: left;
  }

  .con-vs-radio {
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: flex-start;
  }

  .links {
    position: sticky;
    left: 0;
    bottom: 30px;
  }

</style>
