<script>
import common from '../mixins/mixin'

export default {
  name: 'AuthenticatedPage',
  head () {
    return {
      title: 'GOTit Social Ecommerce',
      meta: [
        { hid: 'description', name: 'description', content: process.env.gotitDescription },
        { hid: 'application-name', name: 'application-name', content: 'GOTit' },
        { hid: 'apple-mobile-web-app-capable', name: 'apple-mobile-web-app-capable' },
        {
          hid: 'image',
          name: 'image',
          content: process.env.gotitLogo
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: process.env.gotitLogo
        },
        { hid: 'url', name: 'url', content: 'https://gotitstores.com' },
        { hid: 'og:url', name: 'og:url', content: 'https://gotitstores.com' }
      ]
    }
  },
  mounted () {
    switch (common.methods.getMobileOperatingSystem()) {
      case 'android':
        // location.href = 'https://play.google.com/store/apps/details?id=gotit.com.gotit'
        // break
      // eslint-disable-next-line no-fallthrough
      case 'ios':
        // location.href = 'https://itunes.apple.com/us/app/gotit-social-shopping/id1003017825'
        // break
      // eslint-disable-next-line no-fallthrough
      default:
        this.$root.$on('on_user_ready', () => {
          if (this.onUserReady) {
            this.onUserReady()
          }
          this.$root.$emit('user_is_ready', true)
        })
        if (!this.$store.state.localStorage.sessionToken) {
          // let user
          // try {
          //   user = await this.$fire.auth.signInAnonymously()
          // } catch (e) {
          //   this.$store.dispatch('api/sendMonitorMessage', 'cannot anonymous login: ' + JSON.stringify(e)).then().catch()
          // }
          // const data = await this.$store.dispatch('api/usersLogin', { firebaseUser: user.user.uid })
          this.$store.dispatch('api/usersLogin', { firebaseUser: this.generateUID(28) }).then((data) => {
            const skipPushRsgistration = true
            common.methods.handleCurrentUserResult(this, data, skipPushRsgistration)
            try {
              this.handalePushNotifications(true)
            } catch (e) {
            }
            this.redirectWebUserAction()
          }).catch((err) => {
            console.log(err)
            // TODO anonymouse login failed?
          })
        } else {
          this.$root.$emit('user_is_ready', true)
          this.$store.commit('api/addCurrentUserToBucket', this.$store.state.localStorage.currentUser)
          try {
            this.handalePushNotifications()
          } catch (e) {
          }
          this.redirectWebUserAction()
          // console.log('this.$root.$emit(\'on_user_ready\')')
          // this.$root.$emit('on_user_ready')
          if (this.onUserReady) {
            this.onUserReady()
          }
        }
        break
    }
  },
  methods: {
    redirectWebUserAction () {
      if (!!this.redirectWebUser && this.$store.state.localStorage.currentUser.id < 0) {
        this.$store.commit('api/setCache', { showLoginMenu: true })
        this.$root.$emit('toggle_login_menu', {
          cancelable: this.redirectWebUser
        })
      }
    },
    generateUID (length) {
      return window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2))).map(c => String.fromCharCode(c)).join('')).replace(/[+/]/g, '').substring(0, length)
    },
    handalePushNotifications (login) {
      const messaging = this.$fire.messaging
      try {
        // dev reload invoke this line more then once...
        messaging.usePublicVapidKey('BNIsvlE7G3kIPuOMxLlk77PuKejwc9fOCBBuimMGXLkO3AH9y-jURGQsO19EKJNBgHOTDNf-hhIgH7s4e1d_muA')
      } catch (e) {
      }
      messaging.getToken().then((currentToken) => {
        if (currentToken && this.$store.state.localStorage.currentUser) {
          if (this.$store.state.localStorage.pushToken !== currentToken || login) {
            this.$store.commit('localStorage/updatePushToken', { pushToken: currentToken })
            this.$store.dispatch('api/setDeviceToken', currentToken)
          }
          // eslint-disable-next-line no-unused-vars

          // sendTokenToServer(currentToken)
          // updateUIForPushEnabled(currentToken)
        } else {
          // Show permission request.
          // console.log('No Instance ID token available. Request permission to generate one.')
          // Show permission UI.
          Notification.requestPermission().then((permission) => {
            // console.log('permission', permission)
          })
        }
      }).catch(() => {
      })
      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          if (this.$store.state.localStorage.pushToken !== refreshedToken || login) {
            this.$store.commit('localStorage/updatePushToken', { pushToken: refreshedToken })
            this.$store.dispatch('api/setDeviceToken', refreshedToken)
          }
        }).catch(() => {
        })
      })

      messaging.onMessage((payload) => {
      })
    }
  }
}
</script>
